<template>
    <div class="row" style="margin-left: 0px; margin-right : 0px">
        <div class="col-md-4">
            <div class="main-card card py-3 mb-3">
                <div class="card-header">
                    <h6 class="font-weight-bold" style="font-size: 13px;">
                        <i class="fa fa-list header-icon icon-gradient bg-happy-itmeo" style="font-size: 13px;"></i>
                        Liste des documents / exercice du cours
                    </h6>
                </div>
                <div class="card-body">
                    <template v-if="!loading">
                        <template v-if="documents.documents.length != 0">
                            <template v-for="(item, key) in documents.documents" :key="key">
                                <document-component :body="item" :index="key + 1"></document-component>
                            </template>
                        </template>
                        <template v-else>
                            <div class="py-5">
                                <div class="text-center">
                                    <h6 class="font-weight-bold text-danger">
                                        <i class="fa fa-info-circle"></i>
                                        {{ message }}
                                    </h6>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="main-card card py-3 mb-3">
                <div class="card-header" style="justify-content: space-between;">
                    <h6 class="font-weight-bold" style="font-size: 13px;"><i style="font-size: 13px;"
                            class="fa fa-list header-icon icon-gradient bg-happy-itmeo"></i> Contenu du cours</h6>
                    <div style="text-transform : none;">
                        <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                            <li class="nav-item">
                                <a @click.prevent="switchOnTabs('contenu')" role="tab" class="nav-link"
                                    :class="{ 'active': contenu_seance }" href="#">
                                    <span>Contenu/Séance</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a @click.prevent="switchOnTabs('add')" role="tab" class="nav-link"
                                    :class="{ 'active': add_seance }" href="#">
                                    <span>Ajouter un contenu</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-body">
                    <div class="">
                        <div v-show="contenu_seance">
                            <div class="text-center" v-if="listContenuChapitre.length == 0">
                                <h6 class="font-weight-bold text-danger">
                                    <i class="fa fa-info-circle"></i>
                                    Cette information n'est disponible
                                </h6>
                            </div>
                            <div v-else>
                                <table class="align-middle mb-0 table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr style="background-color: teal; color : white">
                                            <th>Date du cours</th>
                                            <th>Chapitre du cours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item, key) in listContenuChapitre" :key="key">
                                            <tr :id="key">
                                                <td>{{ moment(item.seance) }} de {{ item.horaire }}</td>
                                                <td>{{ item.chapitre }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="" v-show="add_seance">
                            <div v-if="planCours.length == 0" class="text-center">
                                <h6 class="font-weight-bold text-danger">
                                    <i class="fa fa-info-circle"></i>
                                    Aucun chapitre défini pour ce cours
                                </h6>
                            </div>
                            <div v-if="programme.length != 0" class="pb-4">
                                <div class="text-center" v-if="messageError != ''">
                                    <h6 class="font-weight-bold text-danger">
                                        <i class="fa fa-info-circle"></i> {{ messageError }}
                                    </h6>
                                </div>
                                <div class="row">
                                    <div class="col-5">
                                        <label for="">Séance</label>
                                        <select v-model="seance" name="sceance" class="form-control" id="sceance"
                                            data-controle="select2">
                                            <option value="">Choisir la Séance</option>
                                            <template v-for="(item, key) in programme" :key="key">
                                                <option :value="item.idEnseigner">{{ moment(item['dateCours']) }} de {{
                        item['horaire'] }}</option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="col-5">
                                        <label for="">Chapitre</label>
                                        <select v-model="chapitre" name="programme" class="form-control" id="programme"
                                            data-controle="select2">
                                            <option value="">Choisir un chapitre</option>
                                            <template v-for="(item, key) in planCours" :key="key">
                                                <option :value="item.id">{{ item.numeroOrdre + ". " + item.titre }}</option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="col-2">
                                        <button @click="addItem(seance, chapitre)" class="btn btn-success"
                                            style="margin-top : 30px">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <table class="align-middle mb-0 table table-bordered table-striped table-hover">
                                        <thead>
                                            <tr style="background-color: teal; color : white">
                                                <th>Date du cours</th>
                                                <th>Chapitre du cours</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(item, key) in tableContenuChapitre" :key="key">
                                                <tr :id="key">
                                                    <td>{{ moment(item.seance) }}</td>
                                                    <td>{{ item.chapitre }}</td>
                                                    <td>
                                                        <i @click="suppItem(key)" class="btn-icon fas fa-trash"
                                                            style="color : red"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="text-center">
                                    <button @click="sendEnseignerPlan" class="btn btn-success"
                                        style="margin-top : 30px">
                                        Envoyer
                                    </button>
                                </div>
                            </div>
                            <div v-else class="text-center">
                                <h6 class="font-weight-bold text-danger">
                                    <i class="fa fa-info-circle"></i>
                                    Ce cours n'est pas programmé
                                </h6>
                            </div>
                        </div>
                        <div class="text-center" v-show="false">
                            <h6 class="font-weight-bold text-danger">
                                <i class="fa fa-info-circle"></i>
                                Cette information n'est disponible
                            </h6>
                        </div>
                        <!-- <embed
                            v-if="true"
                            type="application/pdf"
                            src="https://easylmd.com/demotest/web/assets/fichiers/ressources/doc_Cours_1_20230405192204.pdf"
                            width="100%"
                            style="max-height: 50rem; min-height: 20rem"/> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DocumentComponent from '@/components/tools/DocumentComponent.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    components: {
        DocumentComponent
    },
    emits: ['getBreadcrumb', 'getButtonRetour'],
    data() {
        return {
            contenu_seance: true,
            add_seance: false,
            messageError: '',
            loading: true,
            documents: {},
            embedSrc: '',
            seance: '',
            groupContenuChapitre: [],
            chapitre: '',
            tableContenuChapitre: [],
            listContenuChapitre: [],
            tabPlan: [],
            planCours: [],
            programme: [],
            message: "",
            objects: {
                breadcrumb: [
                    {
                        libelle: 'Etablissement',
                        name: "index-etablissement",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle: this.$route.params.libelle,
                        name: "index-entite",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle: 'Gestion des cours',
                        name: "cours",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle: 'Contenu du cours',
                        name: "cours"
                    },
                ],
                titlePage: 'Contenu du cours',
                icon: 'fa fa-book'
            }
        }
    },

    async mounted() {
        this.$charging()
        let data = {
            idCours: this.$route.params.id,
            idEntite: this.getEtablissement(this.$route.params.libelle).id,
        }
        await this.getDocumentCours(data).then(async (response) => {
            this.loading = false
            this.documents = response.data.data
            this.objects.titlePage = "Contenu du cours de " + this.documents.cours.matiereUe.matiere.libelle + " pour la classe " + this.documents.cours.matiereUe.ueClasse.classe.libelle
            this.getBreadcrumb()
            this.message = response.data.message

            await this.getPlanCours(data).then((response) => {
                this.planCours = response.data.plan
            })

            await this.getEnseignerChapitre(data).then((response) => {
                this.listContenuChapitre = response.data
                this.createGroupeByTable()
            })

            let data2 = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: 'null',
                finPeriode: 'null',
                withCours: 'programme'
            }
            await this.getCoursPeriode(data2).then(() => {
                this.programme = this.getEmploiTemps.filter((_item) => _item.siExecute == false)
                                                    .filter((item) => item.id == this.$route.params.id)   
            })
            this.$unCharging()

        }).catch(() => {
            // eslint-disable-next-line no-undef
            Swal.fire({
                allowOutsideClick: false,
                title: "Oops...",
                text: `Une erreur s'est produite`,
                icon: "error",
            })
            this.$unCharging()
        })


    },

    computed: {
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters('cours', ['getCours', 'getEmploiTemps', 'getCoursSimple']),
        ...mapGetters(['getDataMenu'])
    },

    methods: {
        ...mapActions('etablissements', ['getEntite']),
        ...mapActions('cours', ['getDocumentCours', 'getPlanCours', 'postEnseignerPlanCours', 'getCoursPeriode', 'getEnseignerChapitre']),

        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

        moment: function (date) {
            // eslint-disable-next-line no-undef
            let day = moment(date).locale('fr').format('llll');
            const str2 = day.charAt(0).toUpperCase() + day.slice(1);
            return str2;
        },

        handlerPreviewFile(url) {
            return this.embedSrc = URL.createObjectURL(new Blob(url))
        },

        addItem(seance, chapitre) {
            if (seance.length == 0 || chapitre.length == 0) {
                this.messageError = "Choisir la séance et le chapitre"
            } else {
                this.messageError = ""
                let chapitre2 = this.planCours.filter((item) => item.id == chapitre)[0]['numeroOrdre'] + ". " + this.planCours.filter((item) => item.id == chapitre)[0]['titre']
                let seance2 = this.programme.filter((item) => item.idEnseigner == seance)[0]['dateCours']
                this.tableContenuChapitre.push({
                    chapitre: chapitre2,
                    seance: seance2
                })

                this.tabPlan.push({
                    id_plan: chapitre,
                    id_enseigner: seance
                })
            }


        },

        reduceFonctionTable(accumulator, currentValue) {
            let index = currentValue['seance']
            if (!(index in accumulator)) {
                accumulator[index] = []
            }
            let data = {
                'chapitre': currentValue['chapitre'],
            }

            accumulator[index].push(data)
            return accumulator;
        },

        createGroupeByTable() {
            this.groupContenuChapitre = this.listContenuChapitre.reduce(this.reduceFonctionTable, {});
        },

        suppItem(key) {
            this.tableContenuChapitre = this.tableContenuChapitre.filter((item, index) => index != key)
            this.tabPlan = this.tableContenuChapitre.filter((item, index) => index != key)
        },

        async sendEnseignerPlan() {
            if (this.tabPlan.length == 0) {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick: false,
                    title: "Oops...",
                    text: 'Aucune information envoyée',
                    icon: "error",
                })
            } else {
                this.$charging()
                let data = {
                    idEntite: this.getEtablissement(this.$route.params.libelle).id,
                    tab: this.tabPlan,
                    idCours: this.$route.params.id
                }
                await this.postEnseignerPlanCours(data).then((response) => {
                    this.listContenuChapitre = response.data
                    this.tableContenuChapitre = []
                    this.message = response.data.message
                    this.$unCharging()
                }).catch(() => {
                    this.$unCharging()
                })
            }
        },

        async switchOnTabs(tabsName) {
            this.contenu_seance = false
            this.add_seance = false
            switch (tabsName) {
                case "contenu":
                    this.contenu_seance = true
                    break;
                case "add":
                    this.add_seance = true
                    break;
                default:
                    break;
            }
        },

        /**
         * Breadcrumb event
         */
        getButtonRetour(data) {
            this.$emit('getButtonRetour', data)
        },
    },

    created() {
        let data = {
            retour: true,
            link: "cours",
            title: "Retour",
            params: { libelle: this.$route.params.libelle }
        }
        this.getButtonRetour(data)
        this.getBreadcrumb()
    },
}
</script>
