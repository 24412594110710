<template>
    <div class="coursTabs">
        <div class="">
            <!-- <div class="form-group col-lg-5 col-md-5 col-xs-12" style="">
                <label for="select" style="font-weight: bold;">Choisir la classe</label>
                <select id="select_1" class="form-control" v-model="classe" name="classe1">
                    <option value="">--- Choisir une classe ---</option>
                    <option value="all">Toutes les classes</option>
                    <template v-for="(item, key) in getClasses" :key="key">
                        <template v-if="classe == item.libelle">
                            <option :value="item.libelle" selected>{{ item.libelle }} </option>
                        </template>
                        <template v-else>
                            <option :value="item.libelle">{{ item.libelle }} </option>
                        </template>
                    </template>
                </select>
            </div> -->
            <filter-component 
                label="Choisir la classe" 
                defaultOptionSelectedLibelle="Toute les classes" 
                defaultOptionSelected="all" 
                inputSelectName="classe" 
                :objetOptions="getClasses" 
                @change-body="changeBody">
            </filter-component>
            
        </div>
        <div class="row" style="margin-left: 0px; margin-right : 0px;">
            <template v-if="bodyCoursSimple.length == 0">
                <div class="col-md-12">
                    <div class="main-card mb-3 card">
                        <div class="card-body">
                            <div class="text-center">
                                <h6 class="font-weight-bold text-danger">
                                    <i class="fa fa-info-circle"></i>
                                    Vous n'avez aucun cours
                                </h6>
                                <p class="text-muted">Vous n'êtes assigné à aucun cours</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <template v-for="(item, key) in bodyCoursSimple" :key="key">
                    <div class="col-6" v-if="key < 4">
                        <card-cours 
                            :key="item.id"
                            :className="'card_' + item.id" 
                            :displayDocAndContenu="true" 
                            :details="false"
                            :classDataName="item.classe" 
                            :courLibelle="item.libelleMatiere" 
                            :modeEva="item.modeval"
                            :nbhcmtd="item.nbhcmtd" 
                            :nbhtp="item.nbhtp" 
                            :nbhtotal="item.nbhtotal"
                            :heureExe="item.heureExecute" 
                            :heureProg="item.heureProgramme" 
                            @doc="document(item.id)"
                            @count="contenu(item.id)" 
                            :countProgramme="countNbreProgramme(item.id)">
                        </card-cours>
                    </div>
                </template>
            </template>
        </div>
    </div>
    <pagination-tool @change-page="changePage" @init-value="chargeSixFirstValue" :get-number-page="getNumberPage"
        nbre-page=4></pagination-tool>
</template>

<script>
import CardCours from '@/components/widgets/cards/CardCours.vue';
import PaginationTool from '@/components/tools/PaginationTool.vue';
import FilterComponent from '@/components/tools/FilterComponent.vue';
import { mapGetters, mapActions } from 'vuex';
import Ls from '@/services/ls'
import { Base64 } from 'js-base64'
export default {
    emits: ['getBreadcrumb', 'getButtonRetour'],
    components: {
        CardCours,
        PaginationTool,
        FilterComponent
    },

    data() {
        return {
            isloading: false,
            classe: 'all',
            bodyCoursSimple: [],
            copyCoursSimple: [],
            objects: {
                breadcrumb: [
                    {
                        libelle: 'Etablissement',
                        name: "index-etablissement",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle: this.$route.params.libelle,
                        name: "index-entite",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle: 'Gestion des cours / Liste de vos cours',
                        name: "cours"
                    },

                ],
                titlePage: 'Vos cours (' + this.$route.params.libelle + ')',
                icon: 'fa fa-book-reader'
            }
        }
    },

    async mounted() {
        this.$nextTick(async () => {
            // eslint-disable-next-line no-undef
            // $(document).on('change', '#select_1', () => {
            //     // eslint-disable-next-line no-undef
            //     this.classe = $('#select_1').val()
            //     this.changeBody()
            // })


            this.$charging()
            let data = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: 'null',
                finPeriode: 'null',
                withCours: 'cours'
            }
           
            await this.getClasse(data).catch((error) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick: false,
                    title: "Oops...",
                    text: `${error.data.message}`,
                    icon: "error",
                })
            });
            await this.getCoursPeriode(data).then(async () => { 
                this.copyCoursSimple = await this.getCoursSimple
                this.bodyCoursSimple = this.copyCoursSimple
                this.$unCharging() 
            });
        })
    },

    computed: {
        ...mapGetters('classes', ['getClasses']),
        ...mapGetters('cours', ['getCours', 'getEmploiTemps', 'getCoursSimple']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters(['getDataMenu']),

        getNumberPage() {
            return this.$getNumberPage(this.copyCoursSimple)
        }
    },

    methods: {
        ...mapActions('cours', ['getCoursPeriode', 'sendDocumentCours']),
        ...mapActions('etablissements', ['getEntite']),
        ...mapActions('classes', ['getClasse']),
        ...mapActions('note', ['getNotes']),

        /**
         * format Date with moment.js
         * @param {String} date 
         */
        formatDate(date) {
            // eslint-disable-next-line no-undef
            return moment(date).locale('fr').format('llll')
        },

        /** 
         * Modal to send document on cours
         * @param {Integer} idCours 
         */
        document(idCours) {
            var elementHTML = this.$footerModalButton('fermer', 'sauvegarde', 'loading')
            // eslint-disable-next-line no-undef
            $('.modal-footer').html(elementHTML)
            // eslint-disable-next-line no-undef
            let sauvegarde = $("#sauvegarde");
            // eslint-disable-next-line no-undef
            let fermer = $("#fermer");
            // eslint-disable-next-line no-undef
            let bodyModal = $("#bodyModal");
            // eslint-disable-next-line no-undef
            let formModal = $("#formModal");
            // eslint-disable-next-line no-undef
            let declenche = $("#declenche");
            // eslint-disable-next-line no-undef
            $('#exampleModalLabel').html('Déposer un document');

            bodyModal.html('');
            formModal.attr('class', 'modal-dialog modal-lg');
            fermer.html('Annuler');
            sauvegarde.html("Sauvegarder").show();
            bodyModal.html(this.$formulaireDepotDocument());
            declenche.trigger('click');

            // eslint-disable-next-line no-undef
            let doc = $("#doc");
            // eslint-disable-next-line no-undef
            let select7 = $("#select7");
            select7.on('change', () => {
                doc.html('')
                if (select7.val() == "exo") {
                    doc.html('').append(`
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-6">
                                <label for="datelimite" style="font-weight: bold;">Date limite</label>
                                <input id="datelimite" type="date" class="form-control form-control-solid" name="datelimite">
                            </div>
                            <div class="form-group col-md-6 col-xs-6">
                                <label for="file" style="font-weight: bold;">Fichier max : 2Mo (.doc, .docx, .pdf, .xls)<sup style="color:red">*</sup></label>
                                <input id="file" type="file" class="form-control form-control-solid" name="file">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-6">
                                <label for="description" style="font-weight: bold;">Description</label>
                                <textarea id="description" class="form-control form-control-solid" cols="40" rows="4"></textarea>
                            </div>
                        </div>
                    `);
                } else {
                    doc.html('').append(`
                        <div class="row">
                            <div class="col-md-6 col-xs-6">
                                <label for="file" style="font-weight: bold;">Fichier max : 2Mo (.doc, .docx, .pdf, .xls) <sup style="color:red">*</sup></label>
                                <input id="file" type="file" class="form-control form-control-solid" name="file">
                            </div>
                            <div class="col-md-6 col-xs-6">
                                <label for="description" style="font-weight: bold;">Description</label>
                                <textarea id="description" class="form-control form-control-solid" cols="40" rows="4"></textarea>
                            </div>
                        </div>
                    `);
                }

            });
            sauvegarde.on('click', async () => {
                // eslint-disable-next-line no-undef
                let file = $("#file");
                // eslint-disable-next-line no-undef
                let datelimite = $("#datelimite");
                // eslint-disable-next-line no-undef
                let libelle = $("#libelle");
                // eslint-disable-next-line no-undef
                let description = $("#description");
                if (file[0] == undefined || libelle.val().length == 0 || select7.val().length == 0) {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick: false,
                        title: "Oops...",
                        text: (select7.val().length == 0) ? `Choisir le type de document` : (libelle.val().length == 0 ? `Entrer le libellé du document` : `Choisir le fichier et respecter la taille exigée 2Mo`),
                        icon: "error",
                    })
                } else {
                    await this.sendDocument(file, datelimite, libelle, description, select7, idCours, fermer)
                }

            })
        },

        /**
         * Send document function 
         * @param {File} file 
         * @param {Date} datelimite 
         * @param {String} libelle 
         * @param {String} description 
         * @param {String} select7 
         * @param {Integer} idCours 
         * @param {String} fermer 
         */
        async sendDocument(file, datelimite, libelle, description, select7, idCours, fermer) {
            const formData = new FormData();
            formData.append('file', file[0].files[0]);
            formData.append('datelimite', datelimite.val());
            formData.append('libelle', libelle.val());
            formData.append('description', description.val());
            formData.append('type', select7.val());
            formData.append('id_compte', Base64.decode(Ls.get('user.id')));
            formData.append('id_entite', this.getEtablissement(this.$route.params.libelle).id);
            formData.append('id_cours', idCours)

            await this.sendDocumentCours(formData).then((response) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick: false,
                    title: "Connexion réussie",
                    text: `${response.data.message}`,
                    icon: "success",
                }).then(() => {
                    fermer.trigger('click')
                })
            }).catch((error) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick: false,
                    title: "Oops...",
                    text: `${error.data.message}`,
                    icon: "error",
                })
            })

        },

        /**
         * Breadcrumb event
         */
        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

        /**
      * Change value of body
      */
        changeBody(value) {
            this.classe = value
            this.copyCoursSimple = this.$changeBody(this.classe, this.getCoursSimple)
            this.bodyCoursSimple = this.copyCoursSimple
        },

        /**
         * Breadcrumb event
         */
        getButtonRetour(data) {
            this.$emit('getButtonRetour', data)
        },

        countNbreProgramme(idCours) {
            let count = this.getEmploiTemps.filter((item) => item.id == idCours)
            return count.length
        },

        chargeSixFirstValue() {
            if (this.classe == "all") {
                this.bodyCoursSimple = this.copyCoursSimple.filter((item, id) => id < 6)
            } else {
                this.bodyCoursSimple = this.copyCoursSimple.filter((item, id) => item.classe === this.classe && id < 6)
            }
        },

        changePage(nbrElement) {
            if (this.classe == "all") {
                this.bodyCoursSimple = this.copyCoursSimple.filter((item, id) => id >= nbrElement)
            } else {
                this.bodyCoursSimple = this.copyCoursSimple.filter((item, id) => item.classe === this.classe && id >= nbrElement)
            }
        },


        /**
         * Get contenu of cours
         * @param {Integer} idCours 
         */
        contenu(idCours) {
            this.$router.push({ name: 'document-cours', params: { libelle: this.$route.params.libelle, id: idCours } })
        },
    },

    created() {
        if (!this.$route.params.classeId) {
            this.getBreadcrumb()
        } else {
            let data = {
                retour: true,
                link: "cours",
                title: "Retour",
                params: { libelle: this.$route.params.libelle, classeId: this.$route.params.classeId }
            }
            this.getButtonRetour(data)
        }
    }

}
</script>

<!-- <style scoped>
    .v-enter-active {
        transition: opacity 0.1s ease;
    }
    /* .v-leave-active {
        
    } */

    .v-enter-from, .v-leave-to {
        opacity: 0.5;
    }
</style> -->