<template>
    <div class="row" style="margin-left: 0px; margin-right : 0px; width : 100%">
        <div class="col-md-12">
            <div class="main-card card mb-3">
                <div class="card-header py-5">
                    <div class="row" style="margin-left: 6%; margin-right : 0px; width:100%">
                        <div class="form-group col-md-4 col-xs-3">
                            <label for="select" style="font-weight: bold;">Choisir la classe</label>
                            <select id="select"  class="form-control"  v-model="classe" name="classe">
                                <option value="">--- Choisir une option ---</option>
                                <template v-for="(item, key) in getClasses" :key="key">
                                    <template v-if="classe == item.id">
                                        <option :value="item.id" selected>{{ item.libelle }} </option>
                                    </template>
                                    <template v-else>
                                        <option :value="item.id">{{ item.libelle }} </option>
                                    </template>
                                </template>
                            </select>
                        </div>
                        <div class="form-group col-md-4 col-xs-3">
                            <label for="select" style="font-weight: bold;">Choisir la matière</label>
                            <select id="select2"  class="form-control"  v-model="coursId" name="matiere">
                                <option value="">--- Choisir une option ---</option>
                                <template v-for="(item, key) in matieres" :key="key">
                                    <template v-if="coursId == item.id">
                                        <option :value="item.id" selected>{{ item.libelleMatiere }} </option>
                                    </template>
                                    <template v-else>
                                        <option :value="item.id">{{ item.libelleMatiere }} </option>
                                    </template>
                                    
                                </template>
                            </select>
                        </div>
                        <div class="mt-4">
                            <button @click.prevent="loadNote" class="btn btn-success btn-sm">
                                Consulter les notes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template v-if="tableShow">
        <div v-if="message != null" class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <div class="text-center">
                    <h6 class="font-weight-bold text-danger">
                        <i class="fa fa-info-circle"></i>
                        {{ message }}</h6>  
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="getAllNotes != null">
            <div class="row" style="margin-left: 0px; margin-right : 0px; width : 100%">
                <div class="col-md-12">
                    <div class="main-card card mb-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="mb-2">
                                        <span class="font-weight-bold" style="font-size : 15px">
                                            Département : 
                                        </span>
                                        <span>
                                             {{ getOneCours.matiereUe.ueClasse.classe.departement.libelle }}
                                        </span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-weight-bold" style="font-size : 15px">
                                            Classe : 
                                        </span>
                                        <span>
                                             {{ getOneCours.matiereUe.ueClasse.classe.libelle }}
                                        </span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-weight-bold" style="font-size : 15px">
                                            Niveau : 
                                        </span>
                                        <span>
                                             {{ getOneCours.matiereUe.ueClasse.classe.niveau.libelle }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-2">
                                        <span class="font-weight-bold" style="font-size : 15px">
                                            Option : 
                                        </span>
                                        <span>
                                             {{ getOneCours.matiereUe.ueClasse.classe.option }}
                                        </span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-weight-bold" style="font-size : 15px">
                                            Unité d'enseignement : 
                                        </span>
                                        <span>
                                             {{ getOneCours.matiereUe.ueClasse.ue.libelle }}
                                        </span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-weight-bold" style="font-size : 15px">
                                            Matière : 
                                        </span>
                                        <span>
                                             {{ getOneCours.matiereUe.matiere.libelle }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="">
                                        <span class="font-weight-bold" style="font-size : 15px">
                                            Effectifs : 
                                        </span>
                                        <span>
                                             {{ getOneCours.matiereUe.ueClasse.classe.effectif }}
                                        </span>
                                    </div>
                                    <div class="">
                                        <span class="font-weight-bold" style="font-size : 15px">
                                            Semestre : 
                                        </span>
                                        <span>
                                             {{ getOneCours.matiereUe.ueClasse.semestre.libelle }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="border-bottom font-weight-bold">Statistique des notes</h5>

            <div class="row" style="margin-left: 0px; margin-right : 0px; width : 100%">
                <div class="col-md-12">
                    <div class="main-card card mb-3">
                        <div class="card-header" style="justify-content : space-between">
                            <h6 class="font-weight-bold" style="font-size: 13px;">
                                <i style="font-size: 13px;" class="fa fa-signal header-icon icon-gradient bg-happy-itmeo"></i> 
                                Statistique des moyennes obtenues
                            </h6>
                            <div>
                                <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav" style="text-transform : uppercase">
                                    <li class="nav-item">
                                        <span @click.prevent="activeTabs(true)" role="tab" class="nav-link" :class="{'active' : absenceTabs}" href="#">
                                            <span class="font-weight-bold">MOYENNE ECUE</span>
                                        </span>
                                    </li>
                                    <li class="nav-item">
                                        <span @click.prevent="activeTabs(false)" role="tab" class="nav-link" :class="{'active' : !absenceTabs}" href="#">
                                            <span class="font-weight-bold">VALIDATION DE L'UE</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body">
                            <table v-if="absenceTabs" class="align-middle mb-0 table table-bordered table-hover">
                                <thead style="font-size : 110%">
                                    <tr style="background-color: teal; color : white">
                                        <th>Moyenne de la classe</th>
                                        <th>Effectif ayant la moyenne</th>
                                        <th>Effectif n'ayant pas la moyenne</th>
                                        <th>Plus forte Moyenne</th>
                                        <th>Plus faible Moyenne</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="font-size : 100%; font-weight : bold">
                                        <td>{{ moy.toFixed(2).replace('.', ',') }}</td>
                                        <td>{{ nbrFort.length }} ({{ ((nbrFort.length * 100)/ getOneCours.matiereUe.ueClasse.classe.effectif).toFixed(2).replace('.', ',') }}%)</td>
                                        <td>{{ nbrFaible.length }} ({{ ((nbrFaible.length * 100) / getOneCours.matiereUe.ueClasse.classe.effectif).toFixed(2).replace('.', ',') }}%)</td>
                                        <td>{{ max }}</td>
                                        <td>{{ min }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else class="align-middle mb-0 table table-bordered table-hover">
                                <thead style="font-size : 110%">
                                    <tr style="background-color: teal; color : white">
                                        <th>Moyenne de la classe dans l'UE</th>
                                        <th>Effectif ayant validé l'UE</th>
                                        <th>Effectif n'ayant pas validé l'UE</th>
                                        <th>Plus forte Moyenne de l'UE</th>
                                        <th>Plus faible Moyenne de l'UE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="font-size : 100%; font-weight : bold">
                                        <td>{{ moyUe.toFixed(2).replace('.', ',') }}</td>
                                        <td>{{ nbrFortUe.length }} ({{ ((nbrFortUe.length * 100)/ getOneCours.matiereUe.ueClasse.classe.effectif).toFixed(2).replace('.', ',') }}%)</td>
                                        <td>{{ nbrFaibleUe.length }} ({{ ((nbrFaibleUe.length * 100) / getOneCours.matiereUe.ueClasse.classe.effectif).toFixed(2).replace('.', ',') }}%)</td>
                                        <td>{{ maxUe }}</td>
                                        <td>{{ minUe }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-left: 0px; margin-right : 0px; width : 100%">
                <div class="col-md-12">
                    <div class="main-card card mb-3">
                        <div class="card-header" style="justify-content : space-between">
                            <h6 class="font-weight-bold" style="font-size: 13px;">
                                <i style="font-size: 13px;" class="fa fa-signal header-icon icon-gradient bg-happy-itmeo"></i> 
                                Statistique des moyennes obtenues par intervalle
                            </h6>
                            <div>
                                <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav" style="text-transform : uppercase">
                                    <li class="nav-item">
                                        <span @click.prevent="activeTabs2(true)" role="tab" class="nav-link" :class="{'active' : absenceTabs2}" href="#">
                                            <span class="font-weight-bold">MOYENNE ECUE</span>
                                        </span>
                                    </li>
                                    <li class="nav-item">
                                        <span @click.prevent="activeTabs2(false)" role="tab" class="nav-link" :class="{'active' : !absenceTabs2}" href="#">
                                            <span class="font-weight-bold">VALIDATION DE L'UE</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="offset-3 col-lg-6 mb-4">
                                    <label for="">Nombre d'intervalle d'appréciation</label>
                                    <input @keyup="addInput($event)" type="number" min="1" name="" id="" class="form-control form-solid">
                                </div>
                            </div>
                            
                            <template v-if="intervalles.length != 0">
                                <div class="row" style="margin-left: 0px; margin-right : 0px;">
                                    <div class="col-md-12">
                                        <div class="card main-card bg-danger mb-2" style="opacity : 0.7;">
                                            <div class="card-body text-white">
                                                <div class="d-flex" style="justify-content: center;">
                                                    <i class="fa fa-info-circle" style="width: 3%; font-size : 150%"></i>
                                                    <h6>La borne inférieure du premier intervalle doit être 00 et la borne supérieure du dernier intervalle doit être 20</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <h6 class="text-center" style="font-weight : bold">Définir les intervalles d'appréciation (Ex : debut-fin)</h6>
                                <div class="row mb-2" v-if="intervalles.length != 0">
                                    <div class="col-lg-3" v-for="(item, key) in intervalles" :key="key">
                                        <input placeholder="Ex : 08-10" type="text" v-model="item.value" class="form-control mb-2">
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button @click="getIntervalleData()" class="btn btn-success form-solid mb-3">
                                        Lancez les intervalles d'appréciations
                                    </button>
                                </div>
                            </template>
                            <template v-if="absenceTabs2">
                                <table v-if="intervallesData.length != 0" class="align-middle mb-0 table table-bordered table-striped table-hover" id="tab2">
                                    <thead style="font-size : 110%">
                                        <tr style="background-color: teal; color : white">
                                            <th>Intervalles</th>
                                            <th>Effectifs</th>
                                            <th>Pourcentages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, key) in intervallesData" :key="key" style="font-size : 100%; font-weight : bold">
                                            <td>[{{ item.intervalle }}]</td>
                                            <td>{{ (key == 0) ? item['moyennes'].length + (getOneCours.matiereUe.ueClasse.classe.effectif - totalIntervalle) : item['moyennes'].length}}</td>
                                            <td>{{ (key == 0) ? (((item['moyennes'].length + (getOneCours.matiereUe.ueClasse.classe.effectif - totalIntervalle)) * 100) / getOneCours.matiereUe.ueClasse.classe.effectif).toFixed(2).replace('.', ',') : ((item['moyennes'].length * 100) / getOneCours.matiereUe.ueClasse.classe.effectif).toFixed(2).replace('.', ',') }}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                            <template v-else>
                                <table v-if="intervallesData.length != 0" class="align-middle mb-0 table table-bordered table-striped table-hover" id="tab2">
                                    <thead style="font-size : 110%">
                                        <tr style="background-color: teal; color : white">
                                            <th>Intervalles</th>
                                            <th>Effectifs pour l'UE</th>
                                            <th>Pourcentages pour l'UE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, key) in intervallesData" :key="key" style="font-size : 100%; font-weight : bold">
                                            <td>[{{ item.intervalle }}]</td>
                                            <td>{{ (key == 0) ? item['moyennesUe'].length + (getOneCours.matiereUe.ueClasse.classe.effectif - totalIntervalleUe) : item['moyennesUe'].length}}</td>
                                            <td>{{ (key == 0) ? (((item['moyennesUe'].length + (getOneCours.matiereUe.ueClasse.classe.effectif - totalIntervalleUe)) * 100) / getOneCours.matiereUe.ueClasse.classe.effectif).toFixed(2).replace('.', ',') : ((item['moyennesUe'].length * 100) / getOneCours.matiereUe.ueClasse.classe.effectif).toFixed(2).replace('.', ',') }}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="border-bottom font-weight-bold mt-1">Fiche de note</h5>
            <div v-if="noteDisplay" class="row" style="margin-left: 0px; margin-right : 0px; width : 100%">
                <div class="col-md-12">
                    <div class="main-card card mb-2">
                        <div class="card-header" style="justify-content : space-between">
                            <h6 class="font-weight-bold" style="font-size: 13px;"><i style="font-size: 13px;" class="fa fa-list header-icon icon-gradient bg-happy-itmeo"></i> Fiche de note</h6>
                            <button @click="displayNote(false)" class="btn btn-danger">
                                Cacher la fiche de note
                            </button>
                        </div>
                        <div class="card-body">
                            <table class="align-middle mb-0 table table-bordered table-striped table-hover" id="tabNote">
                                <thead style="background-color: teal;">
                                    <tr class="text-white">
                                        <th class="text-center">#</th>
                                        <th class="text-center">Matricule</th>
                                        <th class="text-center">Noms et Prénoms</th>
                                        <th class="text-center">Sexe</th>
                                        <th class="text-center" v-for="(item, key) in getAllEvaluations" :key="key">{{ item.libelle }}</th>
                                        <th class="text-center">Moy</th>
                                    </tr>  
                                </thead>
                                <tbody>
                                    <tr v-for="(item, key) in getAllNotes" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ item.etudiant.matricule }}</td>
                                        <td>{{ item.etudiant.nom+" "+item.etudiant.prenom }}</td>
                                        <td class="text-center">{{ item.etudiant.sexe }}</td>
                                        <template v-for="(value, index) in item.note" :key="index">
                                            <td class="text-center" v-if="!(item.etudiant.hideResult)">{{ value.note }}</td>
                                            <td class="text-center" v-else>{{ '####' }}</td>
                                        </template>
                                        <template v-if="item.moyenne < item.moyenneValid">
                                            <td class="badge badge-danger" style="text-align: center !important;">{{ (item.siAffiche == "show") ? item.moyenne : ((item.siAffiche == "none") ? "-" : "###") }}</td>
                                        </template>
                                        <template v-else-if="item.moyenne >= item.moyenneValid">
                                            <td class="badge badge-success" style="background-color: #008080;">{{ (item.siAffiche == "show") ? item.moyenne : ((item.siAffiche == "none") ? "-" : "###") }}</td>
                                        </template>
                                        <template v-else>
                                            <td class="text-center">{{ item.moyenne }}</td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="col-md-12">
                <div class="main-card mb-3 card">
                    <div class="card-body">
                        <div class="text-center">
                        <h6 class="font-weight-bold">
                            <i class="fa fa-info-circle"></i>
                           Afficher la fiche de note</h6>
                           <button @click="displayNote(true)" class="btn btn-success">
                                Afficher la fiche de note
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <div class="text-center">
                    <h6 class="font-weight-bold text-danger">
                        <i class="fa fa-info-circle"></i>
                        Aucune évaluation validée</h6>
                        <p class="text-muted">Aucune évaluation n'a été validée pour cette matière</p>  
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <div class="text-center">
                    <h6 class="font-weight-bold text-danger">
                        <i class="fa fa-info-circle"></i>
                        Choisir une classe et une matière</h6>  
                        <p class="text-muted">Aucune matière ou classe sélectionner</p>  
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
//import Ls from '@/services/ls'
//import { Base64 } from 'js-base64'
export default {
    emits: ['getBreadcrumb'],
    components : {
    },

    data() {
        return {
            message : null,
            absenceTabs : true,
            absenceTabs2 : true,
            tableShow : false,
            isloading : false,
            noteDisplay : false,
            classe : '',
            cours : '',
            min : 0,
            minUe : 0,
            max : 0,
            maxUe : 0,
            moy : 0,
            moyUe : 0,
            nbrFort : 0,
            nbrFortUe : 0,
            nbrFaibleUe : 0,
            totalIntervalle : 0,
            totalIntervalleUe : 0,
            nbrFaible : 0,
            coursId : '',
            matieres : [],
            intervallesData : [],
            moyenneData : [],
            intervalles : [],
            numberTable : ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
            header: [
                {
                    classe: '',
                    libelle: 'Classe',
                },
                {
                    classe: '',
                    libelle: 'Matière',
                },
            ],
            objects : {
                breadcrumb : [
                    {
                        libelle : 'Etablissement',
                        name : "index-etablissement",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle : this.$route.params.libelle,
                        name : "index-entite",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle: 'Gestion des cours',
                        name: "cours",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle : 'Fiche de note',
                        name : "note"
                    },
                ],
                titlePage : 'Session normal ('+this.$route.params.libelle+')',
                icon : 'fa fa-sticky-note'
            }
            
        }
    },

    async updated() {
        if(this.tableShow){
            // eslint-disable-next-line no-undef
            $('#tabNote').DataTable()
        }
    },

    async mounted() {
        // eslint-disable-next-line no-undef
        let element = $('#select')
        // eslint-disable-next-line no-undef
        let element2 = $('#select2')
        element.select2()
        element2.select2()
       
        element.on('change', async () => {
            this.$charging()
            this.classe = element.val()
            if(this.classe == ""){
                this.matieres = []
            }else{
                this.matieres = []
                let data = {
                    idClasse : this.classe,
                    idEntite : this.getEtablissement(this.$route.params.libelle).id,
                }
                await this.getMatiereByClasse(data).then((response) => {
                    this.matieres = response.data.data.matieres
                    this.$unCharging()

                }).catch(() => {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                     allowOutsideClick : false,
                        title: "Oops...",
                        text: `Une erreur s'est produite`,
                        icon: "error",
                    })
                    this.$unCharging()
                })
            }
        })

        element2.on('change', async () => {
            this.coursId = element2.val()
        })

        if(this.$route.params.id){
            this.coursId = this.$route.params.id
            this.loadNote()
            
        }
    },

    computed : {
        ...mapGetters('classes', ['getClasses']),
        ...mapGetters('cours', ['getCours', 'getOneCours']),
        ...mapGetters('notes', ['getAllNotes', 'getAllSessions', 'getAllEvaluations']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters(['getDataMenu'])
    },

    methods : {
        ...mapActions('etablissements', ['getEntite']),
        ...mapActions('matieres', ['getMatiereByClasse']),
        ...mapActions('notes', ['getNotes', 'getNotesByIntervalles']),

        getBreadcrumb(){
            this.$emit('getBreadcrumb', this.objects)        
        },

        activeTabs(bool){
            this.absenceTabs = bool
        },

        activeTabs2(bool){
            this.absenceTabs2 = bool
        },

        displayNote(bool){
            this.noteDisplay = bool
        },

        addInput($event){
            this.intervalles = []
            setTimeout(() => {
                if(!isNaN($event.target.valueAsNumber)){
                    for (let index = 0; index < $event.target.valueAsNumber; index++) {
                        this.intervalles.push({
                            value : ""
                        })
                    }
                }
            }, 500)
            
        },

        async loadNote(){
            this.$charging()
            this.message = null
            this.tableShow = false
            if(this.coursId == ""){
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops",
                    text: "Préciser la classe et la matière",
                    icon: "error",
                })
                this.$unCharging()
            }else{
                let data = {
                    idCours : this.coursId,
                    idEntite : this.getEtablissement(this.$route.params.libelle).id,
                }
                await this.getNotes(data).then(async (response) => {
                    this.tableShow = true
                    if(response.data.data.length != 0){
                        this.classe = this.getOneCours.matiereUe.ueClasse.classe.id
                        this.coursId = this.getOneCours.id
                        let moyenneValid = parseFloat(this.getAllNotes[0]['moyenneValid'].replace(',', '.'))
                        let moy = this.getAllNotes.map((item) => (item["moyenne"] != "00,00") ? parseFloat(item['moyenne'].replace(',', '.')) : parseFloat("00.00"))
                        let moyUe = this.getAllNotes.map((item) => (item["moyenneUe"] != "00,00") ? parseFloat(item['moyenneUe']['moyenne'].replace(',', '.')) : parseFloat("00.00"))
                        // eslint-disable-next-line no-undef
                        this.min = _.min(moy)
                        // eslint-disable-next-line no-undef
                        this.max = _.max(moy)
                        // eslint-disable-next-line no-undef
                        this.moy = _.mean(moy)
                        // eslint-disable-next-line no-undef
                        this.minUe = _.min(moyUe)
                        // eslint-disable-next-line no-undef
                        this.maxUe = _.max(moyUe)
                        // eslint-disable-next-line no-undef
                        this.moyUe = _.mean(moyUe)
                        this.nbrFort = moy.filter((item) => item >= moyenneValid)
                        this.nbrFaible = moy.filter((item) => item < moyenneValid)
                        this.nbrFortUe = moyUe.filter((item) => item >= moyenneValid)
                        this.nbrFaibleUe = moyUe.filter((item) => item < moyenneValid)
                        if(this.$route.params.id){
                            let data = {
                                idClasse : this.classe,
                                idEntite : this.getEtablissement(this.$route.params.libelle).id,
                            }
                            await this.getMatiereByClasse(data).then((response) => {
                                this.matieres = response.data.data.matieres
                            })
                        }
                    }else{
                        this.getAllNotes = []
                    }
                    this.$unCharging()

                }).catch((error) => {
                    this.tableShow = true
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: `${error.data.message}`,
                        icon: "error",
                    })
                    this.$unCharging()
                })
            }
            
        },

        async getIntervalleData(){
            let bool = this.intervalles.map((item) => item.value == "")
            let result = bool.findIndex((item) => item == true)
            if(result == 0){
                 // eslint-disable-next-line no-undef
                 Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops...",
                    text: 'Aucun intervalle défini',
                    icon: "error",
                })
            }else{
                this.$charging()
                this.totalIntervalle = 0
                this.totalIntervalleUe = 0
                let data = {
                    idEntite : this.getEtablissement(this.$route.params.libelle).id,
                    intervalles : this.intervalles,
                    idCours : this.coursId
                };
                await this.getNotesByIntervalles(data).then((response) => {
                    this.intervallesData = response.data.moyenne
                    this.intervallesData.map((item) => this.totalIntervalle += item['moyennes'].length)
                    this.intervallesData.map((item) => this.totalIntervalleUe += item['moyennesUe'].length)
                    this.$unCharging()
                }).catch(() => {
                    this.$unCharging()
                     // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: `Une erreur s'est produite`,
                        icon: "error",
                    })
                })
            }
           
        },

    },
  
    created(){
       this.getBreadcrumb()
    },
}
</script>