<script setup>
import { onMounted,  ref } from 'vue';

// eslint-disable-next-line no-undef
const props = defineProps({
    label: {
        type: String,
        required: true,
    },

    defaultOptionSelectedLibelle: {
        type: String,
        required: true,
    },

    defaultOptionSelected: {
        type: String,
        required: true,
    },

    inputSelectName: {
        type: String,
        required: true,
    },

    objetOptions: {
        type: Object,
        required: true
    }
})

// eslint-disable-next-line no-undef
const emits = defineEmits(['changeBody'])

const optionValue = ref(props.defaultOptionSelected)

onMounted(() => {
    // eslint-disable-next-line no-undef
    $('#select_1').select2()
    // eslint-disable-next-line no-undef
    $(document).on('change', '#select_1', () => {
        // eslint-disable-next-line no-undef
        optionValue.value = $('#select_1').val()
        emits('changeBody', optionValue.value)
    })

    // this.$nextTick(async () => {})
});

</script>

<template>
    <div class="form-group col-lg-5 col-md-5 col-xs-12" style="">
        <label for="select" style="font-weight: bold;">{{ props.label }}</label>
        <select id="select_1" class="form-control" :name="props.inputSelectName">
            <option value="">--- Choisir une option ---</option>
            <option value="all">{{ props.defaultOptionSelectedLibelle }}</option>
            <template v-for="(item, key) in objetOptions" :key="key">
                <template v-if="optionValue == item.libelle">
                    <option :value="item.libelle" selected>{{ item.libelle }} </option>
                </template>
                <template v-else>
                    <option :value="item.libelle">{{ item.libelle }} </option>
                </template>
            </template>
        </select>
    </div>
</template>