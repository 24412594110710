<template>
    <div class="col-md-3 col-xl-3 col-6">
        <div class="card mb-3 widget-content" :class="bgColor">
          <div class="widget-content-wrapper text-white">
            <div class="widget-content-left">
              <div class="widget-heading" style="text-overflow: ellipsis"> {{ title }} </div>
              <div class="widget-subheading"> {{ subtitle }} </div>
            </div>
            <div class="widget-content-right">
              <div class="widget-numbers text-white"><span style="font-size: 85%;"> {{ count }} </span></div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
      title : {type : String, require : true},
      subtitle : {type : String, require : true},
      count : {type : String, require : true},
      bgColor : {type : String, require : true},
    }
}
</script>