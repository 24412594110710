<template>
    <div class="app-page-title mb-3" style="margin-left: 0px; margin-right : 0px;">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div v-show="showIcon"  class="page-title-icon">
                    <i class="fa fa-home icon-gradient bg-mean-fruit"></i>
                </div>
                <div v-show="!showIcon" class="avatar-icon-wrapper mr-3 avatar-icon-xl btn-hover-shine">
                    <div class="avatar-icon rounded">
                      <img :src="logo" alt="Avatar 5">
                    </div>
                </div>
                <div>{{ title }}
                    <div class="page-title-subheading"> {{ description }} </div>
                </div>
            </div>
            <div class="page-title-actions">
                <div v-show="showButton" class="d-inline-block">
                    <button @click.prevent="actionClick" type="button" aria-haspopup="true"
                        aria-expanded="false" class="btn-shadow btn fw-bold" :class="classButton">
                        <span class="btn-icon-wrapper pr-2">
                            <i class="fa-w-20" :class="iconButton"></i>
                        </span>
                        {{ buttonLabel}}
                    </button>
<!-- 
                    <div v-show="false" tabindex="-1" role="menu" aria-hidden="true"
                        class="dropdown-menu dropdown-menu-right">
                        <ul class="nav flex-column">
                            <li class="nav-item">
                                <a class="nav-link">
                                    <i class="nav-link-icon lnr-inbox"></i>
                                    <span> Inbox</span>
                                    <div class="ml-auto badge badge-pill badge-secondary">86</div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">
                                    <i class="nav-link-icon lnr-book"></i>
                                    <span> Book</span>
                                    <div class="ml-auto badge badge-pill badge-danger">5</div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">
                                    <i class="nav-link-icon lnr-picture"></i>
                                    <span> Picture</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a disabled class="nav-link disabled">
                                    <i class="nav-link-icon lnr-file-empty"></i>
                                    <span> File Disabled</span>
                                </a>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        title : {type: String, require: true},
        buttonLabel : {type : String, require : false},
        description : {require:true, type:String},
        showButton : {default : false, type : Boolean},
        showIcon : {default : true},
        logo : {type : String, require: false},
        name : {type : String, require: false},
        iconButton : {type : String, require: false},
        iconButtonFirst : {type : String, require: false},
        classButton : {type : String, require: false},
        annee : {type : String, require: false},
        actionClick : {type : Function, require: true}
    }
}
</script>