<template>
   <div class="card mb-3">
        <div class="card-header" style="justify-content: space-between">
            <div style="font-size: 13px;">
                <i class="fa fa-signal header-icon icon-gradient bg-happy-itmeo" style="font-size: 13px;"> </i>{{ title }}
            </div>
        </div>
        <div class="card-body">
            <div :id="idElement"></div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        title : {type : String},
        idElement : {type : String},
        onGraph : {type : String},
        value : {type : Object},
        colorGraph : {type : Object},
        height : {type : String},
    },

    watch: {
        value : {
            handler() {
            this.$chartCircle(this.idElement, this.value, this.colorGraph, this.height)
        },
    deep : true}
  },

    mounted() {
        switch (this.onGraph) {
            case 'circle':
                this.$chartCircle(this.idElement, this.value, this.colorGraph, this.height)
                break;
        
            default:

                break;
        }
    },
}
</script>