<template>
    <breadcrumb-component :breadcrumb="breadcrumb" :icon="icon" :titlePage="titlePage"></breadcrumb-component>
    <router-view @getBreadcrumb="loadBreadcrumb"></router-view>
</template>

<script>
import BreadcrumbComponent from '@/components/widgets/breadcrumb/BreadcrumbComponent.vue';
import { mapActions,mapGetters } from 'vuex'

export default {
    emits : ['getMenu'],
    components: {
        BreadcrumbComponent
    },

    data : function() {
        return {
            icon : '',
            titlePage : '',
            menus_2 : [
                {
                    icon : 'fa fa-home',
                    libelle : 'Liste des entités',
                    subTitle : [],
                    name : "index-etablissement",
                    linkActive : false
                },
                {
                    icon : 'fa fa-briefcase',
                    libelle : 'Etablissements',
                    subTitle : [],
                    name : 'index-show-etablissement',
                    linkActive : false,
                    show : false,
                }
            ],
            menus_1 : [
                {
                    icon : 'fa fa-home',
                    libelle : 'Mes entités',
                    subTitle : [],
                    name : "index-etablissement",
                    linkActive : (this.$route.name == 'index-etablissement') ? true : false,
                },
                {
                    icon : 'fa fa-school',
                    libelle : 'Etablissements',
                    subTitle : [],
                    show : true,
                    name : 'index-show-etablissement',
                    linkActive : false,
                },
                {
                    icon : 'fa fa-graduation-cap',
                    show : (this.$route.name == 'cours' || this.$route.name == 'cours-details' || this.$route.name == 'cours-liste' || this.$route.name == 'document-cours' || this.$route.name == 'classe' || this.$route.name == 'matiere' || this.$route.name == 'emploi-du-temps' || this.$route.name == 'note' || this.$route.name == 'classe-cours') ? true : true,
                    libelle : this.$route.params.libelle,
                    linkActive : (this.$route.name == 'cours' || this.$route.name == 'cours-details' || this.$route.name == 'cours-liste' || this.$route.name == 'document-cours' || this.$route.name == 'matiere' || this.$route.name == 'classe' || this.$route.name == 'emploi-du-temps' || this.$route.name == 'note' || this.$route.name == 'classe-cours') ? true : false,
                    subTitle : [
                        {
                            // nameRoute : 'cours',
                            // paramsRoute : this.$route.params.libelle,
                            show : false,
                            isActive : (this.$route.name == 'cours' || this.$route.name == 'cours-liste' || this.$route.name == 'cours-details' || this.$route.name == 'matiere' || this.$route.name == 'document-cours') ? true : false,
                            libelle : 'Gestion des cours',
                            subTitle : [
                                {
                                    nameRoute : 'matiere',
                                    paramsRoute : this.$route.params.libelle,
                                    isActive : (this.$route.name == 'matiere') ? true : false,
                                    libelle : 'Matières',
                                },
                                {
                                    nameRoute : 'cours-liste',
                                    paramsRoute : this.$route.params.libelle,
                                    isActive : (this.$route.name == 'cours-liste') ? true : false,
                                    libelle : 'Mes cours',
                                },
                                {
                                    nameRoute : 'cours',
                                    paramsRoute : this.$route.params.libelle,
                                    isActive : (this.$route.name == 'cours') ? true : false,
                                    libelle : 'Programmations',
                                },
                            ]
                        },
                        {
                            nameRoute : 'classe',
                            paramsRoute : this.$route.params.libelle,
                            isActive : (this.$route.name == 'classe' || this.$route.name == 'classe-cours') ? true : false,
                            libelle : 'Gestion des classes',
                            subTitle : []
                        },
                        {
                            nameRoute : 'emploi-du-temps',
                            paramsRoute : this.$route.params.libelle,
                            isActive : (this.$route.name == 'emploi-du-temps') ? true : false,
                            libelle : 'Emploi du temps',
                            subTitle : []
                        },
                        {
                            // nameRoute : 'note',
                            // paramsRoute : this.$route.params.libelle,
                            isActive : (this.$route.name == 'note') ? true : false,
                            libelle : 'Gestion de note',
                            show : (this.$route.name == 'note') ? true : false,
                            subTitle : [
                                {
                                    nameRoute : 'note',
                                    paramsRoute : this.$route.params.libelle,
                                    isActive : (this.$route.name == 'note') ? true : false,
                                    libelle : 'Session Normale',
                                }
                            ]
                        },
                        
                    ],
                    name : '',
                },
            ],
            breadcrumb : []
        };
    },

    async mounted() {
        if(this.getDataMenu.length == 0){
            await this.getEntite()
        }
        this.menus_1[1].subTitle = this.getDataMenu
        this.menus_2[1].subTitle = this.getDataMenu
    },

    computed : {
        ...mapGetters(['getDataMenu'])
    },

    methods: {
        ...mapActions('etablissements', ['getEntite']),

        getMenu(){
            if(this.$route.params.libelle == ""){
                this.$emit('getMenu', this.menus_2)
            }else{
                this.$emit('getMenu', this.menus_1)
            }
        },

        loadBreadcrumb(data){
            this.breadcrumb = data.breadcrumb
            this.titlePage = data.titlePage
            this.icon = data.icon
        }
    },

    created(){
       this.getMenu()
    },
}
</script>