<template>
    <div class="mb-3" v-if="getNumberPage > 1">
        <div class="row justify-content-md-center">
            <div class="col-xs-auto ml-1 mr-1 " v-for="(item, key) in getNumberPage" :key="key">
                <div class="main-card card border-radius" @click.prevent="changePage(item - 1, item)" style="cursor : pointer; ">
                    <div class="card-body" :class="[activePage === item ? 'bg-primary text-white' : '']">
                        {{ item }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits : ['initValue', 'changePage'],
    props : {
        getNumberPage : {type : Number},
        nbrePage : {type : Number}
    },

    data(){
        return {
            activePage : 1
        }
    },

    methods : {
        changePage(pagePrecedent, pageNumber){
            this.activePage = pageNumber
            if(pagePrecedent === 0){
                this.$emit('initValue')
            }else{
                let nbrElement = (pagePrecedent * this.nbrePage)
                this.$emit('changePage', nbrElement)
            }
        },
    }
}
</script>