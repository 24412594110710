<template>
    <div class="col-sm-6 col-lg-6 col-xl-4">
        <div class="mb-3 profile-responsive card">
          <div class="dropdown-menu-header">
            <div class="dropdown-menu-header-inner" style="background-color: teal; height: 200px;">
              <div class="menu-header-image opacity-1" style="background-image: url('assets/images/dropdown-header/abstract3.jpg')"></div>
              <div class="menu-header-content btn-pane-right">
                <div class="avatar-icon-wrapper mr-3 avatar-icon-xl btn-hover-shine">
                  <div class="avatar-icon rounded">
                    <img :src="etablissement.logo" :alt="etablissement.name">
                  </div>
                </div>
                <div>
                  <h5 class="menu-header-title">{{ etablissement.sigle }}</h5>
                  <h6 class="menu-header-subtitle" style="font-size:80%; text-transform:lowercase;">{{ etablissement.name }}</h6>
                </div>
                <div class="menu-header-btn-pane">
                  <button class="btn btn-success" @click.prevent="$router.push({name : 'index-entite', params : {libelle : etablissement.sigle}})">Accéder</button>
                </div>
              </div>
            </div>
          </div>
          <ul class="list-group list-group-flush">
            <!-- <li class="list-group-item">
              <div class="widget-content pt-4 pb-4 pr-1 pl-1"> 
                <div class="text-center">
                  <h5 class="mb-0">
                    <span class="pr-1" style="font-size:80%">
                      <b class="">{{ etablissement.name }}</b>
                    </span>
                  </h5>
                </div>
              </div>
            </li> -->
            <li class="p-0 list-group-item">
              <div class="grid-menu grid-menu-2col">
                <div class="no-gutters row">
                  <div class="col-sm-5">
                    <div class="p-1">
                      <button class="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-primary">
                        <i class="lnr-phone text-primary opacity-7 btn-icon-wrapper mb-2"></i> <b class="">{{ etablissement.codePhone+" "+etablissement.telephone}}</b>
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <div class="p-1">
                      <button class="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-primary">
                        <i class="lnr-layers text-primary opacity-7 btn-icon-wrapper mb-2"></i> <b class="">{{ etablissement.email }}</b>
                      </button>
                    </div>
                  </div>
                </div>
              </div>  
            </li>
          </ul>
        </div>
    </div>
</template>

<script>

export default {
    props : {
      etablissement : {type:Object, require : true}
    }
}
</script>