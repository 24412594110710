<template>
    <template v-if="getDataMenu.length != 0">
        <breadcrumb-component :breadcrumb="breadcrumb" :titlePage="titlePage" :icon="icon"></breadcrumb-component>
        <button v-if="retour" @click="$router.go(-1)" class="btn btn-primary mb-2"  style="margin-left: 2%;">
             <i class="fa fa-arrow-circle-left"></i>
                {{ buttonTitle }}
            </button>
        <page-title :actionClick="actionClick" classButton="btn-info" iconButton="fa fa-graduation-cap" :logo="etablissement.logo" :showButton="true" :showIcon="false" buttonLabel="Mes établissements" :title="$route.params.libelle" :description="etablissement.name"></page-title>
        <Transition>
        <router-view @getBreadcrumb="loadBreadcrumb" @getButtonRetour="loadButtonRetour" :userDetail="$attrs.user"></router-view>
    </Transition>
    </template>
</template>

<script>
import BreadcrumbComponent from '@/components/widgets/breadcrumb/BreadcrumbComponent.vue';
import PageTitle from '../../../components/includes/PageTitleView.vue'
import { mapActions,mapGetters } from 'vuex'

export default {
    emits : ['getMenu'],
    components: {
        PageTitle,        
        BreadcrumbComponent
    },

    data : function() {
        return {

            retour : false,
            linkButton : '',
            buttonTitle : '',
            etablissement : [],
            params : '',
            logo : "/assets/images/avatars/logo/eneam.jpeg",
            menus : [
                {
                    icon : 'fa fa-home',
                    show : false,                   
                    libelle : 'Mes entités',
                    subTitle : [],
                    name : "index-etablissement",
                    linkActive : (this.$route.name == 'index-etablissement') ? true : false,
                },
                {
                    icon : 'fa fa-school',
                    show : true,
                    libelle : 'Etablissements',
                    subTitle : [],
                    name : 'index-show-etablissement',
                    linkActive : false,
                },
                {
                    icon : 'fa fa-graduation-cap',
                    show : (this.$route.name == 'cours-stat' || this.$route.name == 'cours-liste' || this.$route.name == 'cours' ||  this.$route.name == 'cours-details' || this.$route.name == 'document-cours' || this.$route.name == 'classe' || this.$route.name == 'matiere' || this.$route.name == 'emploi-du-temps' || this.$route.name == 'note' || this.$route.name == 'classe-cours') ? true : true,
                    libelle : this.$route.params.libelle,
                    linkActive : (this.$route.name == 'cours-stat' || this.$route.name == 'cours-liste' || this.$route.name == 'cours' || this.$route.name == 'cours-details' || this.$route.name == 'document-cours' || this.$route.name == 'matiere' || this.$route.name == 'classe' || this.$route.name == 'emploi-du-temps' || this.$route.name == 'note' || this.$route.name == 'classe-cours') ? true : false,
                    subTitle : [
                        {
                            // nameRoute : 'cours',
                            // paramsRoute : this.$route.params.libelle,
                            isActive : (this.$route.name == 'cours-stat' || this.$route.name == 'cours-liste' || this.$route.name == 'cours' || this.$route.name == 'cours-details' || this.$route.name == 'matiere' || this.$route.name == 'document-cours') ? true : false,
                            libelle : 'Gestion des cours',
                            show : (this.$route.name == 'cours-stat' || this.$route.name == 'cours-liste' || this.$route.name == 'cours' || this.$route.name == 'cours-details' || this.$route.name == 'matiere' || this.$route.name == 'document-cours') ? true : false,
                            subTitle : [
                                {
                                    nameRoute : 'matiere',
                                    paramsRoute : this.$route.params.libelle,
                                    isActive : (this.$route.name == 'matiere') ? true : false,
                                    libelle : 'Matières',
                                },
                                {
                                    nameRoute : 'cours-liste',
                                    paramsRoute : this.$route.params.libelle,
                                    isActive : (this.$route.name == 'cours-liste') ? true : false,
                                    libelle : 'Mes cours',
                                },
                                {
                                    nameRoute : 'cours',
                                    paramsRoute : this.$route.params.libelle,
                                    isActive : (this.$route.name == 'cours-stat' || this.$route.name == 'cours') ? true : false,
                                    libelle : 'Cours programmés',
                                },
                            ]
                        },
                        {
                            nameRoute : 'classe',
                            paramsRoute : this.$route.params.libelle,
                            isActive : (this.$route.name == 'classe' || this.$route.name == 'classe-cours') ? true : false,
                            libelle : 'Gestion des classes',
                            subTitle : []
                        },
                        {
                            nameRoute : 'emploi-du-temps',
                            paramsRoute : this.$route.params.libelle,
                            isActive : (this.$route.name == 'emploi-du-temps') ? true : false,
                            libelle : 'Emploi du temps',
                            subTitle : []
                        },
                        {
                            // nameRoute : 'note',
                            // paramsRoute : this.$route.params.libelle,
                            isActive : (this.$route.name == 'note') ? true : false,
                            libelle : 'Gestion de note',
                            show : (this.$route.name == 'note') ? true : false,
                            subTitle : [
                                {
                                    nameRoute : 'note',
                                    paramsRoute : this.$route.params.libelle,
                                    isActive : (this.$route.name == 'note') ? true : false,
                                    libelle : 'Session Normale',
                                }
                            ]
                        },
                        
                    ],
                    name : '',
                },
            ],
            breadcrumb : [],
            titlePage : '',
            icon : ''
        };
    },

    async beforeMount(){
        //console.log("befor:start")
        // this.$charging()
        // if(this.getDataMenu.length === 0){
        //     await this.getEntite()
        // }

        // this.menus[1].subTitle = this.getDataMenu
        // this.etablissement = this.getEtablissement(this.$route.params.libelle)
        // console.log(this.etablissement)

       // await this.getClasse({idEntite : this.etablissement.id})

        // console.log("index-before")
        // console.log("befor:end")

    },

    async mounted() {
        this.$charging()
        if(this.getDataMenu.length === 0){
            await this.getEntite()
        }

        this.menus[1].subTitle = this.getDataMenu
        this.etablissement = this.getEtablissement(this.$route.params.libelle)
        //console.log(this.etablissement)

        await this.getAcessEntite(this.etablissement.id).then( async () => {
            //console.log("index-mounted")
            this.$unCharging()
        })
        //console.log("mounted:end")

    },

    computed : {
        ...mapGetters('etablissements', ['getEtablissements','getEtablissement']),
        ...mapGetters(['getDataMenu']),
    },

    methods: {
        ...mapActions('etablissements', ['getEntite', 'getAcessEntite']),
        ...mapActions('classes', ['getClasse']),

        getMenu(){
            this.$emit('getMenu', this.menus)
        },

        actionClick(){
            this.$router.push({name : "index-etablissement"})
        },

        loadButtonRetour(data){
            this.retour = data.retour
            this.linkButton  = data.link
            this.buttonTitle  = data.title
            this.params = data.params
        },

        loadBreadcrumb(data){
            this.breadcrumb = data.breadcrumb
            this.titlePage = data.titlePage
            this.icon = data.icon
        },

        retourLink(){
            this.$router.push({name : this.linkButton, params : this.params})
        }
    },

    created(){
       this.getMenu()
    },
}
</script>



<style scoped>
.v-enter-active {
  transition: opacity 0.5s ease;
  opacity: 0;
}

.v-enter-to {
  opacity: 1;
}

.v-leave-active {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.v-leave-to {
  opacity: 0;
}
</style>