<template>
    <breadcrumb-component :breadcrumb="breadcrumb" titlePage="Liste des entités" icon="fa fa-home"></breadcrumb-component>
    <page-title :actionClick="actionClick" :annee="getAnneeAcademique" classButton="btn-danger" iconButton="fa fa-retweet" title="Etablissements" description="Liste des établissements où vous intervenez" buttonLabel="Actualiser la liste" :showButton="true"></page-title>
    <div class="row" style="margin-left : 0px; margin-right : 0px">
        <template v-for="(item, key) in getEtablissements" :key="key">
            <card-etablissement :etablissement=item></card-etablissement>
        </template>
    </div>


</template>

<script>
import BreadcrumbComponent from '@/components/widgets/breadcrumb/BreadcrumbComponent.vue'
import CardEtablissement from '@/components/widgets/cards/CardEtablissementView.vue'
import PageTitle from '@/components/includes/PageTitleView.vue'
import { mapActions,mapGetters } from 'vuex'
export default {
    emits : ['getMenu'],
    components: {
        CardEtablissement,
        PageTitle,
        BreadcrumbComponent,
    },

    data : function() {
        return {
            etablissement : [],
            menus : [
                {
                    icon : 'fa fa-home',
                    libelle : 'Mes entités',
                    subTitle : [],
                    name : "index-etablissement",
                    linkActive : (this.$route.name == 'index-etablissement') ? true : false,
                },
                {
                    icon : 'fa fa-school',
                    libelle : 'Etablissements',
                    subTitle : [],
                    linkActive : false,
                    name : 'index-show-etablissement',
                    show : true,
                }
            ],
            breadcrumb : [
                {
                    libelle : 'Liste des entités',
                    name : "index-etablissement"
                },
            ]
        };
    },

    async mounted() {
        if(this.getEtablissements.length == 0){
            this.$charging()
            await this.getEntite().then(() => {
                this.$unCharging()
            })
        }
        this.menus[1].subTitle = this.getDataMenu
    },

    created(){
        this.loadMenu()
    },

    computed : {
        ...mapGetters('etablissements', ['getEtablissements']),
        ...mapGetters(['getDataMenu']),
        ...mapGetters(['getAnneeAcademique'])
    },

    methods: {
        ...mapActions('etablissements', ['getEntite']),
 
        loadMenu(){
            this.$emit('getMenu', this.menus)
        },

        async actionClick(){
            this.$charging()
            await this.getEntite().then(() => {
                this.menus[1].subTitle = this.getDataMenu
                this.$unCharging()
            })       
        }
    },
}
</script>